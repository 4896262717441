import * as http from '@/utils/api'
import {
  CLEAR_STAFF_SEARCH_WORD,
  DELETE_STAFF,
  GET_INVITED_STAFFS,
  GET_STAFF_CHATS,
  GET_STAFF_HISTORIES,
  GET_STAFF_MONTHLY_USAGE,
  GET_STAFF_MONTHLY_USAGE_DETAIL,
  GET_STAFFS,
  GET_COMPANY_STAFFS,
  INPUT_STAFF_SEARCH_WORD,
  PATCH_STAFF,
  POST_STAFF,
  GET_STAFFS_STATUS,
  DELETE_OFFICE,
  GET_STAFF_POST,
  CLEAR_STAFF_POST_RESULT
} from './types'
import {
  ENDPOINT_INVITED_STAFFS,
  ENDPOINT_STAFF,
  ENDPOINT_STAFF_CHATS,
  ENDPOINT_STAFF_HISTORIES,
  ENDPOINT_STAFF_MONTHLY_USAGE,
  ENDPOINT_STAFF_MONTHLY_USAGE_DETAIL,
  ENDPOINT_STAFFS,
  ENDPOINT_COMPANY_STAFFS,
  ENDPOINT_DELETE_OFFICE,
  ENDPOINT_GET_STAFF_POST
} from '@/constants/endpoint'
import {
  LOADING_TYPE_INVITED_STAFFS,
  LOADING_TYPE_POST_STAFF,
  LOADING_TYPE_STAFF_CHATS,
  LOADING_TYPE_STAFF_HISTORIES,
  LOADING_TYPE_STAFF_MONTHLY_USAGE,
  LOADING_TYPE_STAFF_MONTHLY_USAGE_DETAIL,
  LOADING_TYPE_STAFFS,
  LOADING_TYPE_COMPANY_STAFFS,
  LOADING_TYPE_NONE,
  LOADING_TYPE_DELETE_OFFICE,
  LOADING_TYPE_STAFFS_STATUS,
  LOADING_TYPE_STAFF_POST
} from '@/constants/constant'

export default {
  state: {
    invited_staffs: [],
    invited_staffs_total_page: 0,
    staffs: [],
    company_staffs: [],
    staffs_total_page: 0,
    company_staffs_total_page: 0,
    staffs_total_count: 0,
    company_staffs_total_count: 0,
    staff_chats: [],
    staff_chats_total_page: 0,
    staff_histories: [],
    staff_histories_total_page: 0,
    staff_search_word: '',
    staff_monthly_usage: {
      payment_staff_count: 0,
      payment_chat_count: 0,
      basic_fee: 0,
      platinum_fee: 0,
      group_chat_fee: 0
    },
    staff_monthly_usage_detail: {
      staffs: [],
      total_page: []
    },
    enable_staffs: 0,
    affiliation_groups: 0
  },
  mutations: {
    [GET_INVITED_STAFFS](state, responseData) {
      state.invited_staffs = responseData.invited_staffs
      state.invited_staffs_total_page = responseData.total_page
    },
    [GET_STAFFS](state, responseData) {
      state.staffs = responseData.staffs
      state.staffs_total_page = responseData.total_page
      state.staffs_total_count = responseData.total_count
    },
    [GET_COMPANY_STAFFS](state, responseData) {
      state.company_staffs = responseData.staffs
      state.company_staffs_total_page = responseData.total_page
      state.company_staffs_total_count = responseData.total_count
    },
    [GET_STAFF_CHATS](state, responseData) {
      state.staff_chats = responseData.chats
      state.staff_chats_total_page = responseData.total_page
    },
    [GET_STAFF_HISTORIES](state, responseData) {
      state.staff_histories = responseData.histories
      state.staff_histories_total_page = responseData.total_page
    },
    [INPUT_STAFF_SEARCH_WORD](state, payload) {
      state.staff_search_word = payload
    },
    [CLEAR_STAFF_SEARCH_WORD](state) {
      state.staff_search_word = ''
    },
    [GET_STAFF_MONTHLY_USAGE](state, responseData) {
      state.staff_monthly_usage = responseData
    },
    [GET_STAFF_MONTHLY_USAGE_DETAIL](state, responseData) {
      state.staff_monthly_usage_detail.total_page = responseData.total_page
      state.staff_monthly_usage_detail.staffs = responseData.staffs
    },
    [GET_STAFFS_STATUS](state, responseData) {
      state.enable_staffs = responseData.enable_staffs
      state.affiliation_groups = responseData.affiliation_groups
    }
  },
  getters: {
    invited_staffs: state => state.invited_staffs,
    invited_staffs_total_page: state => state.invited_staffs_total_page,
    staffs: state =>
      state.staffs
        ? state.staffs.filter(
            s =>
              s.last_name.indexOf(state.staff_search_word) !== -1 ||
              s.first_name.indexOf(state.staff_search_word) !== -1
          )
        : state.files,
    company_staffs: state =>
      state.company_staffs
        ? state.company_staffs.filter(
            s =>
              s.last_name.indexOf(state.staff_search_word) !== -1 ||
              s.first_name.indexOf(state.staff_search_word) !== -1
          )
        : state.files,
    staffs_total_page: state => state.staffs_total_page,
    company_staffs_total_page: state => state.company_staffs_total_page,
    staffs_total_count: state => state.staffs_total_count,
    company_staffs_total_count: state => state.company_staffs_total_count,
    staff_chats: state => state.staff_chats,
    staff_chats_total_page: state => state.staff_chats_total_page,
    staff_histories: state => state.staff_histories,
    staff_histories_total_page: state => state.staff_histories_total_page,
    staff_search_word: state => state.staff_search_word,
    staff_monthly_usage: state => state.staff_monthly_usage,
    staff_monthly_usage_detail: state => state.staff_monthly_usage_detail,
    enable_staffs: state => state.enable_staffs,
    affiliation_groups: state => state.affiliation_groups
  },
  actions: {
    [GET_STAFFS]: async ({ commit, dispatch }, params) => {
      await http
        .get(ENDPOINT_STAFFS, params, LOADING_TYPE_STAFFS)
        .then(response => {
          commit(GET_STAFFS, response.data)
        })
        .catch(error => {
          http.handleError(dispatch, error)
          throw new Error()
        })
    },
    [GET_COMPANY_STAFFS]: async ({ commit, dispatch }, params) => {
      await http
        .get(ENDPOINT_COMPANY_STAFFS, params, LOADING_TYPE_COMPANY_STAFFS)
        .then(response => {
          commit(GET_COMPANY_STAFFS, response.data)
        })
        .catch(error => {
          http.handleError(dispatch, error)
          throw new Error()
        })
    },
    [POST_STAFF]: async ({ dispatch }, params) => {
      await http
        .post(ENDPOINT_STAFF, params, LOADING_TYPE_POST_STAFF)
        .catch(error => {
          http.handleError(dispatch, error)
          throw new Error()
        })
    },
    [PATCH_STAFF]: async ({ dispatch }, params) => {
      await http
        .patch(ENDPOINT_STAFF, params, LOADING_TYPE_POST_STAFF)
        .catch(error => {
          http.handleError(dispatch, error)
          throw new Error()
        })
    },
    [DELETE_STAFF]: async ({ dispatch }, params) => {
      await http
        .del(ENDPOINT_STAFF, { data: params }, LOADING_TYPE_POST_STAFF)
        .catch(error => {
          http.handleError(dispatch, error)
          throw new Error()
        })
    },
    [GET_INVITED_STAFFS]: async ({ commit, dispatch }, params) => {
      await http
        .get(ENDPOINT_INVITED_STAFFS, params, LOADING_TYPE_INVITED_STAFFS)
        .then(response => {
          commit(GET_INVITED_STAFFS, response.data)
        })
        .catch(error => {
          http.handleError(dispatch, error)
          throw new Error()
        })
    },
    [GET_STAFF_CHATS]: async ({ commit, dispatch }, params) => {
      await http
        .get(ENDPOINT_STAFF_CHATS, params, LOADING_TYPE_STAFF_CHATS)
        .then(response => {
          commit(GET_STAFF_CHATS, response.data)
        })
        .catch(error => {
          http.handleError(dispatch, error)
          throw new Error()
        })
    },
    [GET_STAFF_HISTORIES]: async ({ commit, dispatch }, params) => {
      await http
        .get(ENDPOINT_STAFF_HISTORIES, params, LOADING_TYPE_STAFF_HISTORIES)
        .then(response => {
          commit(GET_STAFF_HISTORIES, response.data)
        })
        .catch(error => {
          http.handleError(dispatch, error)
          throw new Error()
        })
    },
    [INPUT_STAFF_SEARCH_WORD]({ commit }, payload) {
      commit(INPUT_STAFF_SEARCH_WORD, payload)
    },
    [CLEAR_STAFF_SEARCH_WORD]({ commit }) {
      commit(CLEAR_STAFF_SEARCH_WORD)
    },
    [GET_STAFF_MONTHLY_USAGE]: async ({ commit, dispatch }, params) => {
      await http
        .get(
          ENDPOINT_STAFF_MONTHLY_USAGE,
          params,
          params.loading ? LOADING_TYPE_STAFF_MONTHLY_USAGE : LOADING_TYPE_NONE
        )
        .then(response => {
          commit(GET_STAFF_MONTHLY_USAGE, response.data)
        })
        .catch(error => {
          http.handleError(dispatch, error)
          throw new Error()
        })
    },
    [GET_STAFF_MONTHLY_USAGE_DETAIL]: async ({ commit, dispatch }, params) => {
      await http
        .get(
          ENDPOINT_STAFF_MONTHLY_USAGE_DETAIL,
          params,
          params.loading
            ? LOADING_TYPE_STAFF_MONTHLY_USAGE_DETAIL
            : LOADING_TYPE_NONE
        )
        .then(response => {
          commit(GET_STAFF_MONTHLY_USAGE_DETAIL, response.data)
        })
        .catch(error => {
          http.handleError(dispatch, error)
          throw new Error()
        })
    },
    [GET_STAFFS_STATUS]: async ({ commit, dispatch }, params) => {
      await http
        .get(ENDPOINT_DELETE_OFFICE, params, LOADING_TYPE_STAFFS_STATUS)
        .then(response => {
          commit(GET_STAFFS_STATUS, response.data)
        })
        .catch(error => {
          http.handleError(dispatch, error)
          throw new Error()
        })
    },
    [DELETE_OFFICE]: async ({ dispatch }, office_number) => {
      await http
        .del(
          ENDPOINT_DELETE_OFFICE + '/' + office_number,
          {},
          LOADING_TYPE_DELETE_OFFICE
        )
        .catch(error => {
          http.handleError(dispatch, error)
        })
    },
    [GET_STAFF_POST]: async ({ commit, dispatch }, email) => {
      commit(CLEAR_STAFF_POST_RESULT)
      try {
        const response = await http.get(
          `${ENDPOINT_GET_STAFF_POST}/${email}`,
          {},
          LOADING_TYPE_STAFF_POST
        )
        commit(GET_STAFF_POST, response.data)
        return response
      } catch (error) {
        http.handleError(dispatch, error)
        throw error
      }
    }
  }
}
